import { z } from 'zod';

export const imageSchema = z.object({
  id: z.string().uuid(),
  caption: z.string(),
  image: z.string(),
});

export const faqSchema = z.object({
  id: z.string().uuid(),
  question: z.string(),
  answer: z.string(),
  enabled: z.boolean(),
});

export const criteriaSchema = z.object({
  criteria: z.string(),
  rating: z.number(),
});

export const feedbackSchema = z.object({
  id: z.string().uuid(),
  comment: z.string(),
  criteria: z.array(criteriaSchema),
  createdOn: z.string(),
  userId: z.string().uuid(),
});
