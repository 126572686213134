import type { IconButtonProps } from '@mui/material/IconButton';

import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';
import IconButton from '@mui/material/IconButton';

interface Props extends IconButtonProps {
  selected: boolean;
}
export const DotButton = ({ selected, ...props }: Props) => {
  return (
    <IconButton color={selected ? 'secondary' : 'primary'} aria-label={'Slide'} {...props}>
      <Brightness1RoundedIcon fontSize="small" />
    </IconButton>
  );
};
