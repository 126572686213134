import type { IconButtonProps } from '@mui/material/IconButton';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import IconButton from '@mui/material/IconButton';

export const NextButton = (props: IconButtonProps) => {
  return (
    <IconButton
      type="button"
      aria-label="Next"
      {...props}
      sx={(theme) => ({
        bgcolor: theme.palette.primary.main,
        '&:hover': {
          bgcolor: theme.palette.primary.dark,
        },
        top: 'calc(50% - 20px)',
        position: 'absolute',
        right: 16,
      })}>
      <ChevronRightRoundedIcon sx={{ color: 'white' }} />
    </IconButton>
  );
};
