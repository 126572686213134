import { z } from 'zod';

export enum notificationTypeEnum {
  NOTIFICATION_DELETE = 'NOTIFICATION_DELETE',
  BOOKING_CREATED = 'BOOKING_CREATED',
  BOOKING_SUBMITTED = 'BOOKING_SUBMITTED',
  BOOKING_REVISED = 'BOOKING_REVISED',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_DECLINED = 'BOOKING_DECLINED',
  BOOKING_SCHEDULED = 'BOOKING_SCHEDULED',
  BOOKING_CANCELED = 'BOOKING_CANCELED',
  BOOKING_REVIEW = 'BOOKING_REVIEW',
  PROFESSION_CREATED = 'PROFESSION_CREATED',
  PROFESSION_SUBMITTED = 'PROFESSION_SUBMITTED',
  PROFESSION_PUBLISHED = 'PROFESSION_PUBLISHED',
}

export const notificationSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string().datetime({ offset: true }),
  updatedOn: z.string().datetime({ offset: true }),
  message: z.string(),
  refId: z.string().uuid().nullable(),
  activeOn: z.string().datetime({ offset: true }),
  duOn: z.string().datetime({ offset: true }).nullable(),
  link: z.string().nullable(),
  type: z.nativeEnum(notificationTypeEnum),
  dismissible: z.boolean(),
  userId: z.string().uuid(),
});
