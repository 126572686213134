import { z } from 'zod';

const latLngSchema = z.object({
  lat: z.function().args().returns(z.number()),
  lng: z.function().args().returns(z.number()),
});

const predictionSubstringSchema = z.object({
  length: z.number(),
  offset: z.number(),
});

const predictionTermSchema = z.object({
  offset: z.number(),
  value: z.string(),
});

const structuredFormattingSchema = z.object({
  main_text: z.string(),
  main_text_matched_substrings: z.array(predictionSubstringSchema),
  secondary_text: z.string(),
});

export const autocompletePredictionSchema = z.object({
  description: z.string(),
  distance_meters: z.number().optional(),
  matched_substrings: z.array(predictionSubstringSchema),
  place_id: z.string(),
  structured_formatting: structuredFormattingSchema,
  terms: z.array(predictionTermSchema),
  types: z.array(z.string()),
  location: latLngSchema.optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});

export const addressSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  label: z.string(),
  itinerary: z.string().nullable(),
  latitude: z.number(),
  longitude: z.number(),
  placeType: autocompletePredictionSchema.partial(),
  userId: z.string().uuid(),
});

export const insertAddressSchema = addressSchema
  .merge(
    z.object({
      id: z.string().uuid().optional(),
    }),
  )
  .omit({ createdOn: true, updatedOn: true });
