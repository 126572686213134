import { z } from 'zod';

export const feedbackCriteria = [
  {
    id: 'punctuality',
    label: 'Punctuality',
    description: 'Were they on time?',
  },
  {
    id: 'cleanliness',
    label: 'Cleanliness',
    description: 'Was the place clean and neat?',
  },
  {
    id: 'quality-of-service',
    label: 'Quality of Service',
    description: 'How good was the service overall?',
  },
  {
    id: 'courtesy-and-friendliness',
    label: 'Courtesy and Friendliness',
    description: 'Were they polite and friendly?',
  },
] as const;

export const bookingCommentSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  comment: z.string(),
  bookingId: z.string().uuid(),
  userId: z.string(),
});
