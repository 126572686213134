'use client';

import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import type { SxProps, Theme } from '@mui/material/styles';
import type { EmblaOptionsType } from 'embla-carousel';
import AutoScroll from 'embla-carousel-auto-scroll';
import useEmblaCarousel from 'embla-carousel-react';

import { DotButton } from './components/dot-button';
import { NextButton } from './components/next-button';
import { PrevButton } from './components/pre-button';
import { useDotButton } from './components/use-dot-button';
import { usePrevNextButtons } from './components/use-prev-next-buttons';

interface Props {
  cards: React.ReactNode[];
  options?: EmblaOptionsType;
  displayDots?: boolean;
  autoplay?: boolean;
  sxSlide?: SxProps<Theme>;
}

const CardCarousel = ({ displayDots = true, autoplay = false, ...props }: Props) => {
  const { cards, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true, ...options }, [
    AutoScroll({ playOnInit: autoplay, speed: 0.5, stopOnMouseEnter: true, stopOnInteraction: false }),
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  const onButtonAutoplayClick = React.useCallback(
    (callback: () => void) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll;
      if (!autoScroll) {
        return;
      }
      const resetOrStop = autoScroll.options.stopOnInteraction === false ? autoScroll.reset : autoScroll.stop;

      if (autoplay) {
        resetOrStop();
      }

      callback();
    },
    [emblaApi, autoplay],
  );

  return (
    <Box component="section" sx={{ position: 'relative' }} dir={props.options?.direction ?? 'ltr'} /* embla */>
      <Box
        // embla__viewport
        ref={emblaRef}
        sx={{
          overflow: 'hidden',
        }}>
        <Box
          // embla__container
          sx={{
            backfaceVisibility: 'hidden',
            display: 'flex',
            touchAction: 'pan-y pinch-zoom',
          }}
        >
          {cards.map((card, index) => (
            <Box
              // embla__slide
              //TODO:fixable
              // biome-ignore lint/suspicious/noArrayIndexKey: no choice TODO:fixable
              key={index}
              sx={{
                flex: '0 0 100%',
                minWidth: 0,
                pl: props.options?.loop === true ? 1 : 0,
                '&:first-of-type': { pl: props.options?.loop === true ? 1 : 0 },
                ...props.sxSlide,
              }}>
              {card}
            </Box>
          ))}
        </Box>
      </Box>
      <PrevButton onClick={() => onButtonAutoplayClick(onPrevButtonClick)} disabled={prevBtnDisabled} />
      <NextButton onClick={() => onButtonAutoplayClick(onNextButtonClick)} disabled={nextBtnDisabled} />
      {displayDots && (
        <Paper
          variant="soft"
          color="primary"
          sx={{
            position: 'absolute',
            left: `calc(50% - ${(scrollSnaps.length * 36) / 2}px)`,
            bottom: 10,
          }}>
          {scrollSnaps.map((item, index) => (
            <DotButton key={item} selected={index === selectedIndex} onClick={() => onDotButtonClick(index)} />
          ))}
        </Paper>
      )}
    </Box>
  );
};

export default CardCarousel;
