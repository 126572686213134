import { z } from 'zod';

import { addressSchema } from './address';
import { imageSchema } from './common';

export enum professionStatusEnum {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  PUBLISHED = 'PUBLISHED',
  BANNED = 'BANNED',
  CANCELED = 'CANCELED',
  ARCHIVED = 'ARCHIVED',
}

export const serviceTypeSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  image: z.string(),
  professionType: z.string().uuid(), // professionType
  enabled: z.boolean(),
});

export const serviceSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string(),
  price: z.number(),
  serviceType: z.string().uuid(), // serviceTypeSchema
  duration: z.number(),
});

export const professionTypeSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string().optional(),
  image: z.string().optional(),
  enabled: z.boolean(),
  serviceTypes: z.array(serviceTypeSchema),
});

export const orderSchema = z.object({
  booking_on: z.string().datetime({ offset: true }),
  services: z.array(serviceSchema),
});

export const professionSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  title: z.string(),
  coverImage: z.string().nullable(),
  canHost: z.boolean(),
  canMove: z.boolean(),
  description: z.string(),
  publishedOn: z.string().datetime({ offset: true }).nullable(),
  onDemand: z.boolean(),
  userId: z.string().uuid(),
  type: z.string(),

  addresses: z.array(addressSchema),
  images: z.array(imageSchema),
  services: z.array(serviceSchema),
  status: z.nativeEnum(professionStatusEnum),
  tags: z.string().array(),
});

const insertProfessionSchema = professionSchema
  .merge(
    z.object({
      id: z.string().uuid().optional(),
    }),
  )
  .omit({ createdOn: true, updatedOn: true });

export const createProfessionSchema = insertProfessionSchema.pick({
  title: true,
  type: true,
  description: true,
});

export const baseSectionSchema = insertProfessionSchema.pick({
  title: true,
  type: true,
  description: true,
  tags: true,
});

export const professionSubmitSchema = insertProfessionSchema.superRefine((data, ctx) => {
  // 1 Address must be defined
  if (data.canHost && data.addresses.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Address must be defined.',
    });
  }

  // 2 Profession must have services
  if (data.services.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'At least 1 service should be defined.',
    });
  }

  // 3 can_move or can_host should be set to True
  if (data.canHost && !data.canMove) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'You must choose an address option.',
    });
  }
});
