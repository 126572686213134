'use client';

import * as React from 'react';

import Image from 'next/image';

import Box from '@mui/material/Box';

import { getImageUrl } from '~/utils/image';
import CardCarousel from '../card/card-carousel';

interface Props {
  height: number | string;
  displayDots?: boolean;
  slides: { id: string; image: string; width?: number; height?: number }[];
  selected?: number;
}

const ImageCarousel: React.FC<Props> = ({ displayDots = true, selected = 0, height, slides }) => {
  const cards = React.useMemo(() => {
    return slides.map((slide) => {
      return (
        <Box
          key={slide.id}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            flex: '0 0 100%',
            minHeight: height,
          }}>
          <Image alt="" src={getImageUrl('//TODO: find a fallback', slide.image)} fill priority style={{ objectFit: 'cover' }} />
        </Box>
      );
    });
  }, [slides, height]);
  return <CardCarousel cards={cards} options={{ startIndex: selected }} displayDots={displayDots} />;
};
export default ImageCarousel;
