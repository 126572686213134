import type { Dayjs } from 'dayjs';

import dayjs from 'dayjs';
import { z } from 'zod';

export enum languageEnum {
  en = 'en',
  fr = 'fr',
  ru = 'ru',
}

export enum gradeEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  NEW = 'N',
}

export const profileSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string().datetime({ offset: true }),
  updatedOn: z.string().datetime({ offset: true }),
  name: z.string().nullable(),
  email: z.string().email(),
  emailVerified: z.number().optional(),
  image: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  language: z.nativeEnum(languageEnum),
  hasProfession: z.boolean(),
  about: z.string().nullable(),
  dateBirth: z.string().nullable(),
  spokenLanguages: z.string().array(),
  grade: z.nativeEnum(gradeEnum),
});

export const userSchema = profileSchema.omit({
  emailVerified: true,
});

export const insertUserSchema = profileSchema.omit({
  name: true,
  emailVerified: true,
});

export const publicUserSchema = userSchema.pick({
  id: true,
  firstName: true,
  lastName: true,
  image: true,
  createdOn: true,
  about: true,
  hasProfession: true,
  spokenLanguages: true,
  grade: true,
});

export const overviewSchema = insertUserSchema
  .pick({
    firstName: true,
    lastName: true,
    email: true,
    about: true,
    spokenLanguages: true,
  })
  .extend({
    dateBirth: z
      .instanceof(dayjs as unknown as typeof Dayjs)
      .nullish()
      .refine((val) => val === undefined || val === null || val.isValid(), {
        message: 'Invalid',
      }),
  });
