import dayjs from 'dayjs';

export type Level = 'info' | 'warn' | 'error' | 'debug';

export const logger = (message: string, exception: unknown = null, level: Level = 'info') => {
  const prefixDate = `[${dayjs().format('YYYY-MM-DD HH:mm:ss SSS')}] `;
  // eslint-disable-next-line no-restricted-properties
  if (process.env.NODE_ENV === 'development') {
    switch (level) {
      case 'warn':
        console.warn(prefixDate + message, exception ? exception : '');
        break;
      case 'debug':
        console.log(prefixDate + message, exception ? exception : '');
        break;
      case 'error':
        console.error(prefixDate + message, exception ? exception : '');
        break;
      default:
        console.info(prefixDate + message, exception ? exception : '');
        break;
    }
  } else if (level === 'error') {
    console.error(prefixDate + message, exception ? exception : '');
  }
};
