import dayjs from 'dayjs';
import { z } from 'zod';

import { addressSchema } from './address';
import { feedbackSchema } from './common';
import { professionSchema, serviceSchema } from './profession';

export enum bookingStatusEnum {
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  EXECUTED = 'EXECUTED',
  SAVED = 'SAVED',
  CANCELED = 'CANCELED',
}

export enum addressDefinedByEnum {
  CLIENT = 'CLIENT',
  PROFESSION = 'PROFESSION',
}

export const bookingMessageSchema = z.object({
  id: z.string().uuid(),
  agenda: z.boolean(),
  location: z.boolean(),
  user: z.boolean(),
  price: z.boolean(),
  service: z.boolean(),
  other: z.boolean(),
  comment: z.string().optional(),
  created_on: z.string(),
});

export const actionTypeSchema = z.enum(['cancel', 'confirm', 'decline', 'execute']);

export const commentInputSchema = z.object({ comment: z.string().min(1) });

export const bookingSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string().datetime({ offset: true }),
  updatedOn: z.string().datetime({ offset: true }),
  bookingOn: z.string().datetime({ offset: true }),
  reference: z.number(),
  submittedOn: z.string().datetime({ offset: true }).nullable(),
  confirmedOn: z.string().datetime({ offset: true }).nullable(),
  userId: z.string().uuid(),
  profession: professionSchema,
  address: addressSchema.nullable(),
  services: z.array(serviceSchema),
  messages: z.array(bookingMessageSchema),
  status: z.nativeEnum(bookingStatusEnum),
  addressDefinedBy: z.nativeEnum(addressDefinedByEnum),
  feedback: z.array(feedbackSchema),
});

const insertBookingSchema = bookingSchema
  .merge(
    z.object({
      id: z.string().uuid().optional(),
    }),
  )
  .omit({ createdOn: true, updatedOn: true });

export const detailsFormSchema = insertBookingSchema.pick({ bookingOn: true, services: true }).merge(
  z.object({
    bookingOn: z.string().datetime({ offset: true }),
    services: z.array(serviceSchema),
  }),
);

export const coordinateFormSchema = insertBookingSchema.pick({ addressDefinedBy: true, address: true }).merge(
  z.object({
    addressDefinedBy: z.nativeEnum(addressDefinedByEnum),
    address: z.string(),
  }),
);

export const bookingSubmitSchema = insertBookingSchema.superRefine((data, ctx) => {
  // booking_on must be in the future
  if (dayjs(data.bookingOn).isBefore(dayjs())) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Booking date should be in the future.',
    });
  }

  // must have at least one service
  if (data.services.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'You should at least choose one service.',
    });
  }

  // address should be defined
  if (!data.address) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'You should choose an address.',
    });
  }
});

export const bookingConfirmSchema = insertBookingSchema.superRefine((data, ctx) => {
  // booking_on myst be in the future
  if (dayjs(data.bookingOn).isBefore(dayjs())) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Booking date should be in the future.',
    });
  }
});
