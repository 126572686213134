'use client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create } from 'zustand';

import { logger } from '@pegase/helpers/utils';
import { useI18n } from '@pegase/i18n/client';

export interface ConfirmInterface {
  description: string;
  title: string;
  open: boolean;
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
  resolveConfirm: () => void;
  rejectConfirm: () => void;
}

const useConfirm = create<ConfirmInterface>((set, get) => ({
  open: false,
  description: '',
  title: '',
  resolve: () => void logger('Resolve triggered'),
  reject: () => void logger('Reject triggered'),
  resolveConfirm: () => {
    get().resolve('resolved');
    set({
      open: false,
    });
  },
  rejectConfirm: () => {
    get().reject('rejected');
    set({
      open: false,
    });
  },
}));

export const confirm = async (title: string, description: string) => {
  await new Promise((resolve, reject) => {
    useConfirm.setState({
      open: true,
      title: title,
      description: description,
      resolve: resolve,
      reject: reject,
    });
  });
};

const ConfirmDialog = () => {
  const t = useI18n();
  const confirm = useConfirm();

  return (
    <Dialog
      open={confirm.open}
      onClose={confirm.rejectConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{confirm.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{confirm.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={confirm.rejectConfirm}>
          {t('common.cancel')}
        </Button>
        <Button onClick={confirm.resolveConfirm} disableElevation>
          {t('common.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
