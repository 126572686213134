import { z } from 'zod';

import { criteriaSchema, imageSchema } from './common';

export const likeSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  userId: z.string().uuid(),
});

export const ratingSchema = z.object({
  id: z.string().uuid(),
  criteria: z.array(criteriaSchema),
  description: z.string(),
  createdOn: z.string(),
  userId: z.string().uuid(),
});

export const bookingRealizationSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  description: z.string().nullable(),
  userId: z.string().uuid(),
  bookingId: z.string().uuid(),
  professionId: z.string().uuid(),
  average_rating: z.number(),
  image: imageSchema.nullable(),
  ratings: z.array(ratingSchema),
  tags: z.string().array(),
  likes: z.array(likeSchema),
});

export const updateBookingRealizationSchema = bookingRealizationSchema.pick({
  description: true,
  image: true,
  tags: true,
});
