/**
 * Formats a number to a string with a 'K' or 'M' suffix if it's greater than
 * 999 or 999999 respectively.
 * TODO: when given 2500, it should return 2.5 K
 * @param {number} num - The number to format.
 * @return {string} The formatted number as a string.
 */
export const kFormatter = (num: number) => {
  return Math.abs(num) > 9999
    ? `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(2))} M`
    : Math.abs(num) > 999
      ? `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(2))} K`
      : Math.sign(num) * Math.abs(num);
};
