import type * as React from 'react';

import Box from '@mui/material/Box';

import { env } from '~/env';

interface Props {
  children: React.ReactNode;
  enabled?: boolean;
}

const FeatureEnabled = (props: Props) => {
  return env.NODE_ENV !== 'production' ? (
    <Box sx={{ borderColor: 'darkorchid', borderStyle: 'solid', borderRadius: 2 }}>{props.children}</Box>
  ) : null;
};

export default FeatureEnabled;
