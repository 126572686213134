import { z } from 'zod';
import { bookingSchema } from './booking';

export enum occurrenceTypeEnum {
  AVAILABLE = 'AVAILABLE',
  BUSY = 'BUSY',
  BOOKING = 'BOOKING',
}

export const availabilitySchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  duration: z.number(),
  excluded: z.boolean(),
  rrule: z.string(),
  userId: z.string().uuid(),
  exDate: z.string().array(),
});

export const availabilityExtSchema = z.object({
  id: z.string().uuid(),
  createdOn: z.string(),
  updatedOn: z.string(),
  duration: z.number(),
  excluded: z.boolean(),
  type: z.nativeEnum(occurrenceTypeEnum),
  booking: bookingSchema.optional(),
  rrule: z.string(),
  userId: z.string().uuid(),
  exDate: z.string().array(),
});

export const insertAvailabilitySchema = availabilitySchema
  .merge(
    z.object({
      id: z.string().uuid().optional(),
    }),
  )
  .omit({ userId: true, createdOn: true, updatedOn: true });

export const occurrenceSchema = z.object({
  availability: availabilityExtSchema,
  booking: bookingSchema.optional(),
  start: z.date(),
  end: z.date(),
  title: z.string(),
});
