import { languageEnum } from '@pegase/helpers/validators';

import defaultAvatarImage from '~/assets/images/default-avatar.png';
import defaultCoverImage from '~/assets/images/default-cover.jpg';

export const FrequencyPeriod = {
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1,
  YEARLY: 0,
};

export const MAP_DEFAULT_ZOOM = 14;

export const GOOGLE_MAP_ID = '531afdf2642c9ac1';

export const defaultUID = '00000000-0000-0000-0000-000000000000';

export const MAX_POPOVER_NOTIFICATION = 3;

export const PAGE_SIZE = 10;

export const defaultCover = defaultCoverImage.src;

export const defaultAvatar = defaultAvatarImage.src;

export const defaultName = 'guest';

export const defaultLanguage = languageEnum.en;
