'use client';

import type * as React from 'react';

import { usePathname } from 'next/navigation';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

import { useCurrentLocale } from '@pegase/i18n/client';

import Link from './link';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  text: string;
  to: string;
  onClick?: () => void;
}

const ListItemLink = (props: ListItemLinkProps) => {
  const locale = useCurrentLocale();
  const pathname = usePathname();
  const theme = useTheme();

  const _click = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const isSelected = pathname === `/${locale}/${props.to}` || pathname === props.to;

  return (
    <Link href={props.to} underline="none" color={isSelected ? 'primary' : 'inherit'}>
      <ListItem
        disablePadding
        sx={{
          borderLeft: isSelected ? 4 : 0,
          borderLeftColor: theme.palette.primary.dark,
          borderLeftStyle: 'solid',
        }}>
        <ListItemButton selected={isSelected} onClick={_click}>
          {props.icon && <ListItemIcon sx={{ color: 'inherit', minWidth: 0, mr: 1 }}>{props.icon}</ListItemIcon>}
          <ListItemText primary={props.text} primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
export default ListItemLink;
