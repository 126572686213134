'use client';

import * as React from 'react';

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Image from 'next/image';
import type { z } from 'zod';

import type { bookingRealizationSchema } from '@pegase/helpers/validators';

import { styled } from '@mui/material/styles';
import { Link } from '~/shared/tools';
import { defaultCover } from '~/utils/constants';
import { getImageUrl } from '~/utils/image';
import { kFormatter } from '~/utils/number';

interface Props {
  realization: z.infer<typeof bookingRealizationSchema>;
}

const Image2 = styled(Image)(({ theme }) => ({
  width: '100%',
  boxSizing: 'content-box',
  objectFit: 'cover',
  borderRadius: 8,
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  ...theme.applyStyles('dark', {
    borderColor: (theme.vars || theme).palette.primary.dark[600],
  }),
}));

export const BookingRealizationImage = (props: Props) => {
  return (
    <ImageListItem sx={{ borderRadius: 4, cursor: 'grab' }} component="div">
      <Image2
        src={getImageUrl(defaultCover, props.realization.image?.image)}
        alt={props.realization.image?.caption ?? ''}
        width={400}
        height={300}
      />
      <ImageListItemBar
        sx={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, mb: '7px' }}
        title={
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1 }}>
            <Rating
              name="half-rating"
              value={props.realization.average_rating}
              precision={0.5}
              disabled
              sx={{ '&.Mui-disabled': { opacity: 0.6 } }}
            />
            {props.realization.ratings.length > 0 && (
              <>
                <PersonRoundedIcon
                  fontSize="small"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.54)',
                  }}
                />
                <Box
                  sx={{
                    color: 'rgba(255, 255, 255, 0.54)',
                    display: 'inline',
                    fontSize: '0.875rem',
                  }}>
                  {kFormatter(props.realization.ratings.length)}
                </Box>
              </>
            )}
          </Stack>
        }
        subtitle={props.realization.description}
        actionIcon={
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
            aria-label={props.realization.image?.caption}
            component={Link}
            href={`/showcases/${props.realization.id}`}
            target="_blank"
            rel="noopener noreferrer">
            <OpenInNewRoundedIcon />
          </IconButton>
        }
      />
    </ImageListItem>
  );
};
